import { MenuSection } from "../../components";

const Menu = () => {
  return (
    <div className='flex w-full h-auto flex-col items-center justify-center'>
      <MenuSection  />
    </div>
  );
}

export default Menu;