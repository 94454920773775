import {Chicken, Fish, Icecream, Strawberry} from "../components/Assets";

export const data = [

    {
        _id: 2,
        title: 'Strawberries',
        desc: "Fresh Strawberries",
        price: 7.25,
        imgSrc: Strawberry
    },
    {   
        _id: 3,
        title: 'Chicken',
        desc: "Mixed Kebab",
        price: 15.65,
        imgSrc: Chicken
    },
    {
        _id: 4,
        title: 'Tilapia',
        desc: "Roasted Tilapia",
        price: 10.25,
        imgSrc: Fish
    },{
        _id: 1,
        title: 'Icream',
        desc: "Chocolate & Vanila",
        price: 5.25,
        imgSrc: Icecream
    },

]